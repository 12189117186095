<template>
  <div class="login-container" :style="{ 'background-image': `url(${this.loginBg})` }">
    <div class="logo" :style="{ 'backgroundImage': 'url(' + logoUrl + ')' }"></div>
    <div class="login_title">{{pageTitle}}</div>
    <div class="login-body-con">
      <div class="login-form" v-if='!isRegister'>
        <div class="login-form-title">
          <span>账号登录</span>
        </div>
        <div class="login-form-body">
          <el-form ref="loginForm" :model="loginForm" :rules="loginRules" autocomplete="off" label-position="left">
            <el-form-item prop="userName">
              <el-input ref="user" v-model="loginForm.userName" placeholder="请输入用户名" name="user" type="text"
                tabindex="1" autocomplete="off">
                <template slot="prepend">
                  <img src="../assets/images/user.png" />
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input :key="passwordType" ref="password" v-model="loginForm.password" :type="passwordType"
                placeholder="请输入密码" name="password" tabindex="2" autocomplete="off" @keyup.native="checkCapslock"
                @blur="capsTooltip = false" @keyup.enter.native="handleLogin">
                <template slot="prepend">
                  <img src="../assets/images/pass.png" />
                </template>
              </el-input>
            </el-form-item>
            <el-row>
              <el-col :span="10">
                <el-form-item>
                  <el-checkbox class="remember-pass" v-model="checked" @change="rememberPass">记住密码</el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :span="14">
                <el-form-item style="text-align:right">
                  <el-link style="color:#0061e3;" class="register" @click="register">还没有账号？点击注册</el-link>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <el-button :loading="loading" type="primary" class="common-btn loginOrRegister"
          @click.native.prevent="handleLogin">
          登 录
        </el-button>
      </div>

      <div class="login-form" v-if='isRegister'>
        <div class="login-form-title">
          <span>账号注册</span>
        </div>
        <div class="login-form-body">
          <el-form ref="registerForm" :model="registerForm" :rules="registerRules" autocomplete="off"
            label-position="left">
            <el-form-item prop="userName">
              <el-input ref="user" key='registerUser' v-model="registerForm.userName" placeholder="请输入用户名" name="user"
                type="text" tabindex="1" autocomplete="off">
                <template slot="prepend">
                  <img src="../assets/images/user.png" />
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input key='registerPassword' ref="password" v-model="registerForm.password" :type="passwordType"
                placeholder="请输入密码" name="password" tabindex="2" autocomplete="off" @blur="capsTooltip = false">
                <template slot="prepend">
                  <img src="../assets/images/pass.png" />
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="passwordConfirm">
              <el-input key='registerPasswordConfirm' ref="password" v-model="registerForm.passwordConfirm"
                type="password" placeholder="请输入刚才的密码确认是否正确" name="password" tabindex="2" autocomplete="off"
                @blur="capsTooltip = false">
                <template slot="prepend">
                  <img src="../assets/images/pass.png" />
                </template>
              </el-input>
            </el-form-item>
            <el-row>
              <el-col :span="12">
                <el-form-item>
                  <el-link class="register" @click="backLogin">已有账号？返回登录</el-link>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <el-button :loading="loading" type="primary" class="common-btn loginOrRegister"
          @click.native.prevent="handleRegister">
          注 册
        </el-button>
      </div>
    </div>

  </div>
</template>

<script>
import api from '@/api/api'

export default {
  name: 'login',
  data () {
    const validateuser = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入用户名'));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入密码'));
      } else {
        callback();
      }
    };
    const validateConfirmPassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入密码'));
      } else {
        callback()
      }
    };
    return {
      logoUrl: require('@/assets/images/logo.png'),
      loginBg: '',
      loginForm: {
        password: ''
      },
      loginRules: {
        userName: [{ required: true, trigger: 'blur', validator: validateuser }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }]
      },
      registerRules: {
        userName: [{ required: true, trigger: 'blur', validator: validateuser }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }],
        passwordConfirm: [{ required: true, trigger: 'blur', validator: validateConfirmPassword }]
      },

      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      checked: false,
      agreement: false,
      isRegister: false,

      registerForm: {
        password: '',
        passwordConfirm: ''
      },

    };
  },
  created () {
    let info = localStorage.getItem('ecolUserInfo');
    if (info && info.trim()) {
      this.loginForm = JSON.parse(info);
      this.checked = true;
    }
  },
  computed: {
    pageTitle() {
        return this.$store.state.config.top || '南京智能计算中心生态应用平台';
    }
  },
  methods: {
    checkCapslock (e) {
      const { key } = e;
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z';
    },
    register () {
      this.isRegister = true
    },
    showPwd () {
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    rememberPass (checked) {
      if (checked) {
        localStorage.setItem(
          'ecolUserInfo',
          JSON.stringify({
            ...this.loginForm
          })
        );
      }
    },
    backLogin () {
      this.registerForm = {
        password: ''
      }
      this.isRegister = false
    },
    handleLogin () {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.doLogin();
        } else {
          return false;
        }
      });
    },
    doLogin () {
      this.loading = true;
      const params = {
        username: this.loginForm.userName,
        password: this.$encryptedData(this.loginForm.password),
        platformType: 7003,
        isNew: 1,
        isPaas: 1,
        type: 1,
      };
      api.login(params).then(res => {
        if (res.code == 200) {
          if (this.checked) {
            localStorage.setItem(
              'ecolUserInfo',
              JSON.stringify({
                ...this.loginForm
              })
            );
          }
          localStorage.setItem('ecolUserInfo', JSON.stringify(res.data));
          this.loading = false;
          this.$notify({
            title: '成 功',
            message: '登录成功',
            type: 'success'
          });
          api.queryCompanyStatus().then(ret => {
            if (ret.code == 200) {
              if (ret.data) {
                localStorage.setItem('company', JSON.stringify(ret.data))
              }
              // else {
              //   localStorage.setItem('company', JSON.stringify({ status: -1 }))
              // }
              this.$router.push({ path: '/company' });
            }
          })

          //   this.$router.push({ path: '/' });

        }
        // else {
        //   this.$notify({
        //     title: '提 示',
        //     message: res.msg,
        //     type: 'warning'
        //   });
        // }
        this.loading = false;
      })
        .catch(err => {
          this.loading = false;
        });
    },

    handleRegister () {
      this.$refs.registerForm.validate(valid => {
        if (valid) {
          this.doRegister();
        } else {
          return false;
        }
      });
    },
    doRegister () {
      this.loading = true;
      if (this.registerForm.password != this.registerForm.passwordConfirm) {
        this.loading = false;
        return this.$notify({
          title: '提 示',
          message: '两次密码不一致',
          type: 'warning'
        });
      }
      const params = {
        username: this.registerForm.userName,
        password: this.$encryptedData(this.registerForm.password),
        platformType: 7003,
        createWhere: 1,
        registerType: 0,
      }
      api.register(params).then(res => {
        if (res.code == 200) {
          this.$notify({
            title: '提 示',
            message: '注册成功',
            type: 'success'
          });
          this.isRegister = false
        }

        this.loading = false;
      })
        .catch(err => {
          this.loading = false;
        });
    },
  }
};
</script>

<style lang="scss">
/* reset element-ui css */

.login-container {
  width: 100%;
  height: 100vh;
  position: relative;
  background: #fff;

  .logo {
    position: absolute;
    top: 20px;
    left: 42px;
  }
  .el-input {
    height: 40px;
    width: 100%;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(204, 209, 222, 1);

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: #2a3140;
      height: 40px;
      caret-color: #2a3140;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px #f2f3fa inset !important;
        -webkit-text-fill-color: #2a3140 !important;
      }
    }
  }
  .el-input-group__prepend {
    background-color: #fff;
    border-radius: 0px;
    height: 100%;
    border: 0px;
    padding: 0px 12px;
    border-right: 1px solid #eeeeee;
    img {
      width: 18px;
      height: 18px;
    }
  }

  .el-form-item--small .el-form-item__error {
    padding-top: 3px;
    padding-left: 16px;
  }

  .el-form-item {
    height: 40px;
    .icon {
      width: 18px;
      height: 18px;
      display: inline-block;
      margin: 11px 0 11px 19px;
    }
    &:last-child {
      margin-bottom: 4px;
    }
  }
}
</style>

<style lang="scss">
.login-container {
  width: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .login_title {
    position: relative;
    top: 100px;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    color: #efefef;
    background-image: -webkit-linear-gradient(bottom, #fcf8f5, #b8b7b7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    img {
      &:first-child {
        width: 54px;
        height: 54px;
      }
      &:last-child {
        width: 334px;
        height: 29px;
        margin-left: 4px;
      }
    }
  }
  .login-footer {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 17px;
    padding-bottom: 24px;
    text-align: center;
    span {
      &:first-child {
        margin-right: 16px;
      }
    }
  }
  .login-body-con {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding-top: 160px;
  }
  .login-form {
    width: 430px;
    background: #fff;
    border: 1px solid #efefef;
    border-radius: 16px;
    z-index: 2;
    padding: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    box-shadow: 0px 0px 6px 2px #f1f2f3;
    .login-form-title {
      text-align: center;
      font-size: 22px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #2f436b;
      line-height: 30px;
      .login-platform {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2f436b;
        line-height: 20px;
        margin-left: 4px;
      }
    }

    .logo-icon {
      width: 120px;
      height: 120px;
      background: #fff;
      border-radius: 50%;
      position: absolute;
      left: 130px;
      top: -60px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 2px 7px 0px rgba(85, 154, 248, 0.36);

      img {
        width: 84px;
        height: 84px;
      }
    }
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .title-container {
    position: relative;
    padding: 75px 0 16px;

    .title {
      font-size: 24px;
      color: #2a3140;
      text-align: center;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 36px;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: #889aa4;
    cursor: pointer;
    user-select: none;
  }

  .thirdparty-button {
    position: absolute;
    right: 0;
    bottom: 6px;
  }

  .login-btn {
    width: 78px;
    height: 32px;
    background: rgba(42, 49, 64, 1);
    border-radius: 2px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
  }
  .login-form-body {
    margin-top: 32px;
    .login-form-label {
      font-size: 15px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(42, 49, 64, 1);
      line-height: 44px;
    }
  }

  .forget-pwd {
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #1364b6;
    padding-top: 15px;
    text-align: right;
  }
  .remember-pass {
    width: 100%;
    text-align: center;
  }
  .el-button {
    width: 100%;
    height: 54px;
    background: linear-gradient(180deg, #89a2db 0%, #497df8 100%);
    border: 0px;
  }
}
</style>
<style scoped lang="scss">
.register {
  text-align: center;
  cursor: pointer;
}
.register:hover {
  color: #497df7;
}
.loginOrRegister {
  font-size: 20px;
}
</style>
